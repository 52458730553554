import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { Countdown } from '../countdown';
import logo from '../../assets/images/redesign-v2/rafflehouse-logo.svg';
import './Header.css';
import MobileHeaderCountdown from '../mobile-header-countdown';
import TrustBox from '../TrustBox/TrustBox';

const Header = (props) => {
  const [isHidden, setIsHidden] = useState(false);
  const headerRef = useRef();
  const prev = useRef(0);
  let classHide = isHidden ? 'hide' : '';
  const hideHeader = (e) => {
    if (window.scrollY > headerRef.current.offsetHeight) {
      window.scrollY > prev.current ? setIsHidden(true) : setIsHidden(false);
    } else {
      setIsHidden(false);
    }
    prev.current = window.scrollY;
  };
  const renderCountdown = ({ isTop } = {}) => {
    let stepperCountdown = null;
    if (props.raffle != null) {
      if (props.raffle.stepperCountdown) {
        stepperCountdown = props.raffle.stepperCountdown;
      }
    }

    if (
      stepperCountdown != null &&
      stepperCountdown.isActive &&
      dayjs().isBefore(dayjs(stepperCountdown.endsAt))
    ) {
      if (isTop) {
        let value = stepperCountdown.title;
        return (
          <div className='header__top'>
            <MobileHeaderCountdown
              prizeType='not-raffle'
              endsAt={stepperCountdown.endsAt}
              text={value}
            />
            <div className='header-countdown-block'>
              {/* <h3>{stepperCountdown.title}</h3>
              <span className='header-countdown-block__devider'>:</span> */}
              <div>
                {/* <Countdown
                  isHeader
                  prizeType='not-raffle'
                  endsAt={stepperCountdown.endsAt}
                /> */}
              </div>
            </div>
          </div>
        );
      }
      return (
        <div className='header-countdown-block'>
          <h3>{stepperCountdown.title}</h3>
          <span className='header-countdown-block__devider'>:&nbsp;</span>
          <div>
            <Countdown
              isHeader
              prizeType='not-raffle'
              endsAt={stepperCountdown.endsAt}
            />
          </div>
        </div>
      );
    }
  };
  useEffect(() => {
    if (typeof window == 'object') {
      window.addEventListener('scroll', hideHeader);
    }
    return () => {
      if (typeof window == 'object') {
        window.removeEventListener('scroll', hideHeader);
      }
    };
  }, []);
  return (
    <header className={`header ${classHide}`} ref={headerRef}>
      <div className='header__grid'>
        <div className='header__left'>{renderCountdown()}</div>
        <a href={process.env.REACT_APP_BASE_URL} className='header__logo'>
          <img src={logo} alt='raffle-house-logo' />
        </a>
        <nav className='header__nav'>
          <ul>
            <li>
              <a href={process.env.REACT_APP_BASE_URL + '/winners'}>Our Winners</a>
            </li>
            {/* <li>
              <a href={process.env.REACT_APP_BASE_URL + '/bonus-draw'}>Bonus Draw</a>
            </li> */}
            {/* <li>
              <a href={process.env.REACT_APP_BASE_URL + '/how-it-works'}>How It Works</a>
            </li> */}
          </ul>
        </nav>
      </div>
      <div className='header-trustpilot'>
        <div className='trust-wrapper'>
          <TrustBox isHeader />
        </div>
      </div>
    </header>
  );
};

export default Header;
