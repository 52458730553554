import React from 'react'
import { DreamDrawBlock, DreamDrawContent } from 'raffle-house-uikit'
import { dreamDrawFeatures } from '../../lib/static-data'

export default function DreamDraw() {
  return (
    <DreamDrawBlock>
      <DreamDrawContent title='Win this £4 million Surrey Dream Home' features={dreamDrawFeatures}>
      </DreamDrawContent>
    </DreamDrawBlock>
  )
}
