import React from 'react';
import {TitleText} from "raffle-house-uikit";
import PartnersSlider from '../PartnersSlider/PartnersSlider';
import image1 from '../../assets/images/charities/btr_logo.png';
import image6 from '../../assets/images/charities/war_paws.png';
import image16 from '../../assets/images/charities/pipal-tree-logo.png';
import image17 from '../../assets/images/charities/whenyouwishuponastar.png';
import image18 from '../../assets/images/charities/sense.png';
import imageLogo from '../../assets/images/footer/logo-fundraising-regulator.png';
import './styles.css';

const CharityBlock = (props) => {
  const charityImages = [
    { src: image1 },
    { src: image6 },
    { src: image16 },
    { src: image17 },
    { src: image18 },
  ];
  return (
    <div className='charity-block'>
      <TitleText isCentered as='h2' variant={'primary'} color='color-5'>
        Dream big. <br className='mobile-spacer' /> Do good.
      </TitleText>
      <div className='charities-list'>
        <div className='left-side'>
          <p className='title'>
            Your donation.
            <br /> <span>Your choice.</span>
          </p>
          <p className='sub'>
            Win a home and donate to one of our charity partners.
          </p>
          <div className="charity-logo">
            <img src={imageLogo} alt="fundraising-regulator" />
          </div>
        </div>
        <div className='right-side'>
          <PartnersSlider
            isDark
            isTicker
            images={charityImages}
            isCharitySlider
          />
        </div>
      </div>
    </div>
  );
};

export default CharityBlock;
